import parse from "html-react-parser"
import styles from "./Wysiwyg.module.scss"
import LinkButton from "../ui/LinkButton"
import { stripHtmlSpaces } from "../../utils/strip-html-spaces"
import Section from "../Section"

const Wysiwyg = ({ data }) => {
  const { ankerslug, wysiwyg } = data

  return (
    <Section
      id={ankerslug}
      className={styles.wysiwyg}
      sectionSpacing={{
        top: data?.section_padding_top?.value || null,
        bottom: data?.section_padding_bottom?.value || null,
      }}
    >
      <div className={styles.wysiwyg_content}>
        {wysiwyg &&
          wysiwyg.length > 0 &&
          wysiwyg.map((block, i) => {
            switch (block.__typename) {
              case "BardText":
                return <div key={i}>{parse(stripHtmlSpaces(block.text))}</div>
              case "Set_DynamicContent_Wysiwyg_KontaktButton":
                return (
                  <LinkButton
                    key={i}
                    className={"mb-6"}
                    data={{
                      link_text: block.wysiwyg_link_text,
                      link_url: "/kontakt",
                    }}
                  />
                )
              default:
                return
            }
          })}
      </div>
    </Section>
  )
}

export default Wysiwyg
